import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'
import moment from 'moment'

import PostForm from './PostForm'

import { updatePost } from '../services'
import { handleError } from '../../../helpers'

export default function EditPostModal({ visible, onClose, reload, post }) {
	const { register, handleSubmit, formState: { errors } } = useForm({
		defaultValues: { 
			...post,
			expire_at: moment(post.expire_at).format('YYYY-MM-DD'),
			is_published: post.is_published ? 1 : 0,
		}
	})

	const onSubmit = values => {
		updatePost(post.id, values)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Actualizar Registro</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<PostForm register={register} errors={errors} post={post} />
						<Button color="primary" type="submit">Actualizar Registrar</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}