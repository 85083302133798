import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Form, InputGroup } from 'react-bootstrap'
import { Button } from 'reactstrap'
import { message } from 'antd'

import ServerSideSelect from '../../../components/ServerSideSelect'

import { storeSubscriber } from '../services'
import { searchUsers } from '../../Users/services'
import { handleError } from '../../../helpers'

export default function NewSubscriberForm({ modelId, modelType, reload }) {
	const [selectedUser, setSelectedUser] = useState([])
	const { handleSubmit } = useForm({
		defaultValues: {
			subscribable_type: modelType,
			subscribable_id: modelId,
		}
	})

	const onSubmit = values => {
		values.user_id = selectedUser.value
		storeSubscriber(values)
			.then(res => {
				message.success(res.data.message)
				reload()
				setSelectedUser([])
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<InputGroup>
					<ServerSideSelect 
						value={selectedUser}
						placeholder="Seleccione un usuario"
						fetchOptions={searchUsers}
						onChange={value => setSelectedUser(value)}
						style={{ width: '70%', minWidth: '200px', maxWidth: '350px' }}
					/>
					<Button color="primary" type="submit" outline disabled={!selectedUser.value}>
						Inscribir Participante
					</Button>
				</InputGroup>
			</Form>
		</React.Fragment>
	)
}