import React from 'react'
import { Divider, Empty } from 'antd'

import NewCommentForm from './partials/NewCommentForm'
import CommentItem from './partials/CommentItem'

export default function ModelCommentsWidget({ model, modelType, reload }) {
	return (
		<React.Fragment>
			{ model.comments.length > 0 ? (
				<React.Fragment>
					{ model.comments.map( comment => (
						<CommentItem 
							key={comment.id}
							comment={comment}
							reload={reload}
						/>
					)) }
				</React.Fragment>
			) : (
				<Empty 
					description="No se han registrado comentarios aún" 
					image={Empty.PRESENTED_IMAGE_SIMPLE}
				/>
			)}
			<Divider className='my-2' />
			<NewCommentForm 
				modelId={model.id}
				modelType={modelType}
				reload={reload}
			/>
		</React.Fragment>
	)
}