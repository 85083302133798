import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import GiftCardsList from './GiftCardsList'

export default function GiftCards() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/gift_cards" component={GiftCardsList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}