import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

export default function ResponseAnswersModal({ visible, onClose, surveyResponse }) {
	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Respuestas Encuesta #00{surveyResponse.id}</ModalHeader>
				<ModalBody>
					{ surveyResponse.answers.map( answer => (
						<div key={answer.id} className="bg-light mb-2 p-2">
							<strong>{answer.question.name}:</strong>{" "}
							{answer.value}
						</div>
					)) }
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}