import springApi, { getDomain } from '../../services/springApi'

export const getSubscribers = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/subscribers', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getSubscriber = (id, params) => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/subscribers/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeSubscriber = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/subscribers', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateSubscriber = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/subscribers/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteSubscriber = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/subscribers/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchSubscribers = async (name, token) => (
	fetch( getDomain()+`/subscribers?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(subscriber => ({
				label: subscriber.name,
				value: subscriber.id,
			})),
		)
)