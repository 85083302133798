import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

export default function ContestForm({ register, errors, contest }) {
	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Nombre <span className="text-danger">*</span></Form.Label>
				<Form.Control {...register('name', { required:true })} />
				{ errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Descripción <span className="text-danger">*</span></Form.Label>
				<Form.Control as="textarea" {...register('description', { required:true })} style={{ height: '80px' }} />
				{ errors.description && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Fecha Vencimiento <span className="text-danger">*</span></Form.Label>
					<Form.Control type="date" {...register('expire_at', { required: true })} />
					{ errors.expire_at && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Estado <span className="text-danger">*</span></Form.Label>
					<Form.Control
						as="select"
						{...register('is_published', { required: true })}
					>
						<option value="">:: Seleccionar una opción ::</option>
						<option value="1">Publicada</option>
						<option value="0">Borrador</option>
					</Form.Control>
				</Form.Group>
			</Row>
		</React.Fragment>
	)
}