import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import ServerSideSelect from '../../../components/ServerSideSelect'
import { searchDepartments } from '../../Departments/services'

export default function UserForm({ user, register, errors, selectedDepartment, setSelectedDepartment }) {
	return (
		<React.Fragment>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Nombre(s) <span className="text-danger">*</span></Form.Label>
					<Form.Control {...register('name', { required:true })} />
					{ errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Apellido(s) <span className="text-danger">*</span></Form.Label>
					<Form.Control {...register('lastname', { required:true })} />
					{ errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Label>Documento de Identidad <span className="text-danger">*</span></Form.Label>
				<Form.Control {...register('document', { required:true })} />
				{ errors.document && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Correo Electrónico <span className="text-danger">*</span></Form.Label>
				<Form.Control type="email" {...register('email', { required:true })} />
				{ errors.email && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Genero <span className="text-danger">*</span></Form.Label>
				<Form.Control
					as="select"
					{...register('gender', { required: true })}
				>
					<option value="">:: Seleccione una opción ::</option>
					<option value="H">Hombre</option>
					<option value="M">Mujer</option>
				</Form.Control>
				{ errors.gender && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Fecha Nacimiento <span className="text-danger">*</span></Form.Label>
					<Form.Control type="date" {...register('born_at', { required: true })} />
					{ errors.born_at && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Fecha Contratación <span className="text-danger">*</span></Form.Label>
					<Form.Control type="date" {...register('hire_at', { required:true })} />
					{ errors.hire_at && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Label>Ciudad de Nacimiento <span className="text-danger">*</span></Form.Label>
				<Form.Control {...register('birth_city', { required:true })} />
				{ errors.birth_city && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Tipo de Contrato <span className="text-danger">*</span></Form.Label>
					<Form.Control
						as="select"
						{...register('contract_type', { required: true })}
					>
						<option value="">:: Seleccione una opción ::</option>
						<option value="INDEFINIDO">Indefinido</option>
						<option value="FIJO">Término Fijo</option>
						<option value="TEMPORAL">Temporal</option>
					</Form.Control>
					{ errors.contract_type && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Empresa <span className="text-danger">*</span></Form.Label>
					<Form.Control
						as="select"
						{...register('company', { required: true })}
					>
						<option value="">:: Seleccione una opción ::</option>
						<option value="SPRING">Spring</option>
						<option value="TEMPORAL">Temporales</option>
					</Form.Control>
					{ errors.company && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Label>Cargo <span className="text-danger">*</span></Form.Label>
				<Form.Control {...register('position', { required:true })} />
				{ errors.position && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Departamento <span className="text-danger">*</span></Form.Label>
				<ServerSideSelect
					value={selectedDepartment}
					placeholder="Seleccione un departamento"
					fetchOptions={searchDepartments}
					onChange={value => setSelectedDepartment(value)}
					style={{ width: '100%', marginBottom: '15px' }}
				/>
			</Form.Group>
			{ !user && (
				<Row>
					<Form.Group as={Col}>
						<Form.Label>Contraseña <span className="text-danger">*</span></Form.Label>
						<Form.Control {...register("password", {required:true})} placeholder="Mínimo 6 digitos" type="password" />
						{ errors.password && <Form.Text className="text-warning">Ingrese una contraseña de 6 dígitos o más.</Form.Text> }
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>Confirmar contraseña <span className="text-danger">*</span></Form.Label>
						<Form.Control {...register("password_confirmation", {required:true})} placeholder="Ingresar nuevamente" type="password" />
						{ errors.password_confirmation && <Form.Text className="text-warning">Las contraseñas no coinciden.</Form.Text> }
					</Form.Group>
				</Row>
			)}
		</React.Fragment>
	)
}