import React from "react"
import {useSelector} from "react-redux"
import { Switch, Route, Redirect } from "react-router-dom"

import MainLayout from "./layouts/MainLayout"

import Authentication from './containers/Authentication/'
import Error404 from './containers/ErrorsPages/Error404'
import Contests from "./containers/Contests"
import Offers from "./containers/Offers"
import Posts from "./containers/Posts"
import Users from "./containers/Users"
import Rewards from "./containers/Rewards"
import Surveys from "./containers/Surveys"
import Opens from "./containers/Opens"
import GiftCards from "./containers/GiftCards"
import PushNotifications from "./containers/PushNotifications"
import Reactions from "./containers/Reactions"

export default function App() {	
	const token = useSelector(state => state.auth.token)
	
	return (
		<React.Fragment>			
			<Switch>
				<Redirect exact path="/" to="/posts" />

				<Route path="/auth" component={Authentication} />
				{!token && <Redirect to="/auth/login" />}

				<MainLayout>
					<Route path="/contests" component={Contests} />
					<Route path="/offers" component={Offers} />
					<Route path="/posts" component={Posts} />
					<Route path="/users" component={Users} />
					<Route path="/rewards" component={Rewards} />
					<Route path="/surveys" component={Surveys} />
					<Route path="/opens" component={Opens} />
					<Route path="/gift_cards" component={GiftCards} />
					<Route path="/push_notifications" component={PushNotifications} />
					<Route path="/reactions" component={Reactions} />
					
					<Route path="/404" component={Error404} />
				</MainLayout>
			</Switch>
		</React.Fragment>
	);	
}