import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'

import NewPostModal from './partials/NewPostModal'
import PostsTable from './partials/PostsTable'

import { handleError, parsePagination } from '../../helpers'
import { getPosts } from './services'

export default function PostsList() {
	const [posts, setPosts] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const [showNewModal, setShowNewModal] = useState(false)

	useEffect(() => {
		!posts && getPosts({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			include: 'user,categories,tags,opensCount,departments',
			sort: '-created_at',
		})
			.then(res => {
				setPosts(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ posts, pagination, filterType, filterValue ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Noticias y Publicaciones"
				subtitle="Listado de todos las noticias publicados en la plataforma"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setPosts(null)}
				options={[
					{ label: 'Buscar por titulo', value: 'name' },
					{ label: 'Buscar por descripción corta', value: 'excerpt' },
					{ label: 'Buscar por contenido', value: 'description' },
				]}
				ctaText="Agregar Noticia"
				handleCtaClick={() => setShowNewModal(true)}
			/>
			<Card>
				<CardBody>
					<PostsTable 
						posts={posts}
						reload={() => setPosts(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
			{ showNewModal && (
				<NewPostModal 
					visible
					onClose={() => setShowNewModal(false)}
					reload={() => {
						setPosts(null)
						setShowNewModal(false)
					}}
				/>
			)}
		</React.Fragment>
	)
}