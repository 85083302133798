import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'

import { getDepartments } from './services'
import { handleError } from '../../helpers'
import { Empty, Popconfirm, Switch } from 'antd'

export default function DepartmentsAttachedModal({ visible, onClose, departmentsAttached, onToggle, onToggleOnAll }) {
	const [departments, setDepartments] = useState(null)

	useEffect(() => {
		!departments && getDepartments()
			.then(res => setDepartments(res.data.data))
			.catch(error => handleError(error))
	}, [ departments ])

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Departamentos Asociados</ModalHeader>
				<ModalBody>
					{ departments ? (
						<React.Fragment>
							{ departments.length > 0 ? (
								<React.Fragment>
									{ departments.map( department => (
										<div key={department.id}>
											<Switch 
												defaultChecked={
													departmentsAttached.filter(d => d.id === department.id).length > 0
												}
												onChange={() => onToggle(department.id)}
											/>
											<span className='ml-1'>
												{department.name} ({department.group_name})
											</span>
										</div>
									)) }
									<Popconfirm
										title="¿Desea activar todos los departamentos?"
										okText="Activar"
										cancelText="Cancelar"
										onConfirm={onToggleOnAll}
									>
										<Button color="primary" outline className='mt-3'>
											Activar todos los departamentos
										</Button>
									</Popconfirm>
								</React.Fragment>
							) : (
								<Empty description="No existen departamentos creados" />
							)}
						</React.Fragment>
					) : <Spinner />}
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}