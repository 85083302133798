import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'

import NewRewardModal from './partials/NewRewardModal'
import RewardsTable from './partials/RewardsTable'

import { handleError, parsePagination } from '../../helpers'
import { getRewards } from './services'

export default function RewardsList() {
	const [rewards, setRewards] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const [showNewModal, setShowNewModal] = useState(false)

	useEffect(() => {
		!rewards && getRewards({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			include: 'user'
		})
			.then(res => {
				setRewards(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ rewards, pagination, filterType, filterValue ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Reconocimientos al Personal"
				subtitle="Listado de todos los reconocimientos publicados en la plataforma"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setRewards(null)}
				options={[
					{ label: 'Buscar por premio', value: 'name' },
					{ label: 'Buscar por nombre', value: 'user.name' },
					{ label: 'Buscar por cedula', value: 'user.document' },
				]}
				ctaText="Agregar Reconocimiento"
				handleCtaClick={() => setShowNewModal(true)}
			/>
			<Card>
				<CardBody>
					<RewardsTable 
						rewards={rewards}
						reload={() => setRewards(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
			{ showNewModal && (
				<NewRewardModal 
					visible
					onClose={() => setShowNewModal(false)}
					reload={() => {
						setRewards(null)
						setShowNewModal(false)
					}}
				/>
			)}
		</React.Fragment>
	)
}