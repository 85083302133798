import springApi, { getDomain } from '../../services/springApi'

export const getComments = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/comments', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getComment = (id, params) => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/comments/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeComment = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/comments', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateComment = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/comments/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteComment = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/comments/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchComments = async (name, token) => (
	fetch( getDomain()+`/comments?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(comment => ({
				label: comment.name,
				value: comment.id,
			})),
		)
)