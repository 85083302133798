import React from 'react'
import { useForm } from 'react-hook-form'
import { Form, InputGroup } from 'react-bootstrap'
import { Button } from 'reactstrap'
import { message } from 'antd'

import { handleError } from '../../../helpers'
import { storeTag } from '../services'

export default function NewTagForm({ modelType, reload }) {
	const { register, handleSubmit, formState: { errors }, setValue } = useForm({
		defaultValues: {
			group: modelType
		}
	})

	const onSubmit = values => {
		storeTag(values)
			.then(res => {
				message.success(res.data.message)
				reload()
				setValue('name', '')
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<InputGroup>
					<Form.Control 
						{...register('name', { required: true })}
						placeholder="Escriba aquí..."
					/>
					<Button color="primary" type="submit" outline>
						Agregar Etiqueta
					</Button>
				</InputGroup>
				{ errors.name && <Form.Text className='text-warning'>Escriba un nombre</Form.Text> }
			</Form>
		</React.Fragment>
	)
}