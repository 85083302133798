import React from 'react'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Form, InputGroup } from 'react-bootstrap'
import { Button } from 'reactstrap'
import { message } from 'antd'

import { storeComment } from '../services'
import { handleError } from '../../../helpers'

export default function NewCommentForm({ modelId, modelType, reload }) {
	const user = useSelector(state => state.auth.user)
	const { register, handleSubmit, formState: { errors }, setValue } = useForm({
		defaultValues: {
			commentable_type: modelType,
			commentable_id: modelId,
			user_id: user.id
		}
	})

	const onSubmit = values => {
		storeComment(values)
			.then(res => {
				message.success(res.data.message)
				reload()
				setValue('body', '')
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<InputGroup>
					<Form.Control 
						as="textarea"
						{...register('body', { required: true })}
						placeholder="Escriba aquí..."
						style={{ height: '50px' }}
					/>
					<Button color="primary" type="submit" outline>
						Agregar Comentario
					</Button>
				</InputGroup>
				{ errors.body && <Form.Text className='text-warning'>Escriba su comentario</Form.Text> }
			</Form>
		</React.Fragment>
	)
}