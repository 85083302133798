import springApi, { getDomain } from '../../services/springApi'

export const getQuestions = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/questions', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getQuestion = (id, params) => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/questions/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeQuestion = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/questions', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateQuestion = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/questions/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteQuestion = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/questions/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchQuestions = async (name, token) => (
	fetch( getDomain()+`/questions?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(question => ({
				label: question.name,
				value: question.id,
			})),
		)
)