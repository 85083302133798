import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader, Col, Row, Spinner } from 'reactstrap'

import ContestContentWidget from './partials/ContestContentWidget'
import ContestDetailsWidget from './partials/ContestDetailsWidget'

import ModelCategoriesWidget from '../Categories/ModelCategoriesWidget'
import ModelTagsWidget from '../Tags/ModelTagsWidget'
import ModelMediasWidget from '../Medias/ModelMediasWidget'
import ModelCommentsWidget from '../Comments/ModelCommentsWidget'
import ModelSubscribersWidget from '../Subscribers/ModelSubscribersWidget'

import { getContest } from './services'
import { handleError } from '../../helpers'

export default function ContestProfile(props) {
	const id = props.match.params.id
	const [contest, setContest] = useState(null)
	const [reload, setReload] = useState(true)

	useEffect(() => {
		reload && getContest(id, {
			include: 'subscribers.user,medias,comments,categories,tags'
		})
			.then(res => setContest(res.data.data))
			.catch(error => handleError(error))
			.finally(() => setReload(false))
	}, [ reload, id ])

	if(!contest) return <Spinner />

	const morphProps = {
		model: contest,
		modelType: "contest",
		reload: () => setReload(true)
	}

	return (
		<React.Fragment>
			<Row>
				<Col md="4">
					<Card>
						<CardHeader>
							<h6 className='mb-0'>Detalles Generales</h6>
						</CardHeader>
						<CardBody>
							<ContestDetailsWidget contest={contest} reload={() => setContest(null)} />
						</CardBody>
					</Card>
					<Card>
						<CardHeader>
							<h6 className='mb-0'>Categorías de Concursos</h6>
						</CardHeader>
						<CardBody>
							<ModelCategoriesWidget {...morphProps} />
						</CardBody>
					</Card>
					<Card>
						<CardHeader>
							<h6 className='mb-0'>Etiquetas de Concursos</h6>
						</CardHeader>
						<CardBody>
							<ModelTagsWidget {...morphProps} />
						</CardBody> 
					</Card>
					<Card>
						<CardHeader>
							<h6 className='mb-0'>Multimedia</h6>
						</CardHeader>
						<CardBody>
							<ModelMediasWidget {...morphProps} />
						</CardBody>
					</Card>
				</Col>
				<Col>
					<Card>
						<CardHeader>
							<h6 className='mb-0'>Descripción del Concurso</h6>
						</CardHeader>
						<CardBody>
							<ContestContentWidget contest={contest} />
						</CardBody>
					</Card>
					<Card>
						<CardHeader>
							<h6 className='mb-0'>Participantes Inscritos</h6>
						</CardHeader>
						<CardBody>
							<ModelSubscribersWidget {...morphProps} />
						</CardBody>
					</Card>
					<Card>
						<CardHeader>
							<h6 className='mb-0'>Comentarios</h6>
						</CardHeader>
						<CardBody>
							<ModelCommentsWidget {...morphProps} />
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}