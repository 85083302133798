import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import PostsList from './PostsList'
import PostProfile from './PostProfile'

export default function Posts() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/posts" component={PostsList} />
				<Route exact path="/posts/:id" component={PostProfile} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}