import React, { useState, useEffect } from 'react'
import { Spinner } from 'reactstrap'
import { Divider, Empty } from 'antd'

import NewCategoryForm from './partials/NewCategoryForm'
import CategoryItem from './partials/CategoryItem'

import { getCategories } from './services'
import { handleError } from '../../helpers'

export default function ModelCategoriesWidget({ model, modelType, reload }) {
	const [categories, setCategories] = useState(null)

	useEffect(() => {
		!categories && getCategories({
			'filter[group]': modelType
		})
			.then(res => setCategories(res.data.data))
			.catch(error => handleError(error))
	}, [ categories, modelType ])

	if(!categories) return <Spinner size="sm" />

	return (
		<React.Fragment>
			{ categories.length > 0 ? (
				<React.Fragment>
					{ categories.map( category => (
						<CategoryItem 
							key={category.id}
							category={category}
							model={model}
							modelType={modelType}
							reload={reload}
							reloadCategories={() => setCategories(null)}
						/>
					)) }
				</React.Fragment>
			) : (
				<Empty 
					description="No se han creado categorías aún"
					image={Empty.PRESENTED_IMAGE_SIMPLE}
				/>
			)}
			<Divider className='mt-2 mb-2' />
			<NewCategoryForm 
				modelType={modelType}
				reload={() => setCategories(null)}
			/>
		</React.Fragment>
	)
}