import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

import ServerSideSelect from '../../../components/ServerSideSelect'
import { searchUsers } from '../../Users/services'

export default function OfferForm({ register, errors, selectedUser, setSelectedUser }) {
	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Tipo de Clasificado <span className="text-danger">*</span></Form.Label>
				<Form.Control
					as="select"
					{...register('is_selling', { required: true })}
				>
					<option value="">:: Seleccionar una opción ::</option>
					<option value="1">Quiero Vender / Recomendar</option>
					<option value="0">Quiero Comprar / Encontrar</option>
				</Form.Control>
			</Form.Group>
			{ selectedUser && (
				<Form.Group>
					<Form.Label>Autor <span className="text-danger">*</span></Form.Label>
					<ServerSideSelect
						value={selectedUser}
						placeholder="Seleccione un usuario"
						fetchOptions={searchUsers}
						onChange={value => setSelectedUser(value)}
						style={{ width: '100%', marginBottom: '7px' }}
					/>
				</Form.Group>
			)}
			<Form.Group>
				<Form.Label>Título <span className="text-danger">*</span></Form.Label>
				<Form.Control {...register('name', { required:true })} />
				{ errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Descripción Corta <span className="text-danger">*</span></Form.Label>
				<Form.Control as="textarea" {...register('excerpt', { required:true })} style={{ height: '80px' }} />
				{ errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Teléfono de Contacto <span className="text-danger">*</span></Form.Label>
				<Form.Control {...register('contact_number', { required:true })} />
				{ errors.contact_number && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Fecha Vencimiento <span className="text-danger">*</span></Form.Label>
					<Form.Control type="date" {...register('expire_at', { required: true })} />
					{ errors.expire_at && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Estado <span className="text-danger">*</span></Form.Label>
					<Form.Control
						as="select"
						{...register('is_published', { required: true })}
					>
						<option value="">:: Seleccionar una opción ::</option>
						<option value="1">Publicada</option>
						<option value="0">Borrador</option>
					</Form.Control>
				</Form.Group>
			</Row>
		</React.Fragment>
	)
}