import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import ReactionsList from './ReactionsList'

export default function Reactions() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/reactions" component={ReactionsList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}