import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import RewardsList from './RewardsList'

export default function Rewards() {
	return (
		<React.Fragment>
			<Switch>
				<Route path="/rewards" component={RewardsList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}