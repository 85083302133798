import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader, Col, Row, Spinner } from 'reactstrap'

import PostContentWidget from './partials/PostContentWidget'
import PostDetailsWidget from './partials/PostDetailsWidget'

import ModelCategoriesWidget from '../Categories/ModelCategoriesWidget'
import ModelTagsWidget from '../Tags/ModelTagsWidget'
import ModelMediasWidget from '../Medias/ModelMediasWidget'
import ModelCommentsWidget from '../Comments/ModelCommentsWidget'

import { getPost } from './services'
import { handleError } from '../../helpers'

export default function PostProfile(props) {
	const id = props.match.params.id
	const [post, setPost] = useState(null)
	const [reload, setReload] = useState(true)

	useEffect(() => {
		reload && getPost(id, {
			include: 'user,medias,comments,categories,tags'
		})
			.then(res => setPost(res.data.data))
			.catch(error => handleError(error))
			.finally(() => setReload(false))
	}, [ reload, id ])

	if(!post) return <Spinner />

	const morphProps = {
		model: post,
		modelType: "post",
		reload: () => setReload(true)
	}

	return (
		<React.Fragment>
			<Row>
				<Col md="4">
					<Card>
						<CardHeader>
							<h6 className='mb-0'>Detalles Generales</h6>
						</CardHeader>
						<CardBody>
							<PostDetailsWidget post={post} reload={() => setReload(true)} />
						</CardBody>
					</Card>
					<Card>
						<CardHeader>
							<h6 className='mb-0'>Categorías de Noticias</h6>
						</CardHeader>
						<CardBody>
							<ModelCategoriesWidget {...morphProps} />
						</CardBody>
					</Card>
					<Card>
						<CardHeader>
							<h6 className='mb-0'>Etiquetas de Noticias</h6>
						</CardHeader>
						<CardBody>
							<ModelTagsWidget {...morphProps} />
						</CardBody> 
					</Card>
				</Col>
				<Col>
					<Card>
						<CardHeader>
							<h6 className='mb-0'>Contenido</h6>
						</CardHeader>
						<CardBody>
							<PostContentWidget post={post} />
						</CardBody>
					</Card>
					<Card>
						<CardHeader>
							<h6 className='mb-0'>Multimedia</h6>
						</CardHeader>
						<CardBody>
							<ModelMediasWidget {...morphProps} />
						</CardBody>
					</Card>
					<Card>
						<CardHeader>
							<h6 className='mb-0'>Comentarios</h6>
						</CardHeader>
						<CardBody>
							<ModelCommentsWidget {...morphProps} />
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}