import React, { useState } from 'react'
import { Descriptions, Divider } from 'antd'
import { Badge, Button } from 'reactstrap'
import moment from 'moment'
import EditOfferModal from './EditOfferModal'

const { Item } = Descriptions

export default function OfferDetailsWidget({ offer, reload }) {
	const [showEditModal, setShowEditModal] = useState(false)

	return (
		<React.Fragment>
			<div className='mb-2'>
				{offer.is_selling ? <Badge color='info'>Venta</Badge> : <Badge color='danger'>Compra</Badge>}
				<Divider type="vertical" />
				{offer.is_published ? <Badge color='success'>Publicado</Badge> : <Badge color='warning'>Borrador</Badge>}
			</div>
			<Descriptions size="small" column={1}>
				<Item label="Título">{offer.name}</Item>
				<Item label="Resumen">{offer.excerpt}</Item>
				<Item label="ID Publicación">#00{offer.id}</Item>
				<Item label="Fecha Creación">{moment(offer.created_at).format('YYYY-MM-DD HH:mm')}</Item>
				<Item label="Fecha Última Modificación">{moment(offer.updated_at).format('YYYY-MM-DD HH:mm')}</Item>
			</Descriptions>
			<div className='mt-2'>
				<Button color="primary" outline onClick={() => setShowEditModal(true)}>
					Actualizar Datos
				</Button>
			</div>
			{ showEditModal && (
				<EditOfferModal 
					visible
					onClose={() => setShowEditModal(false)}
					offer={offer}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}