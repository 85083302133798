import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'

import ReactionsTable from './partials/ReactionsTable'

import { handleError, parsePagination } from '../../helpers'
import { getReactions } from './services'

export default function ReactionsList() {
	const [reactions, setReactions] = useState(null)
	const [filterType, setFilterType] = useState('model.name')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		!reactions && getReactions({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			include: 'model,user',
			sort: '-created_at'
		})
			.then(res => {
				setReactions(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ reactions, pagination, filterType, filterValue ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Registros de Reacciones"
				subtitle="Listado de todos las reacciones registradas en la plataforma"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setReactions(null)}
				options={[
					{ label: 'Buscar titulo de articulo', value: 'model.name' },
					{ label: 'Buscar por cedula del usuario', value: 'user.document' },
					{ label: 'Buscar contenido del comentario', value: 'model.body' },
				]}
			/>
			<Card>
				<CardBody>
					<ReactionsTable 
						reactions={reactions}
						pagination={pagination}
						setPagination={setPagination}
						reload={() => setReactions(null)}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}