import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader, Col, Row, Spinner } from 'reactstrap'

import OfferContentWidget from './partials/OfferContentWidget'
import OfferDetailsWidget from './partials/OfferDetailsWidget'

import ModelCategoriesWidget from '../Categories/ModelCategoriesWidget'
import ModelTagsWidget from '../Tags/ModelTagsWidget'
import ModelMediasWidget from '../Medias/ModelMediasWidget'
import ModelCommentsWidget from '../Comments/ModelCommentsWidget'

import { getOffer } from './services'
import { handleError } from '../../helpers'

export default function OfferProfile(props) {
	const id = props.match.params.id
	const [offer, setOffer] = useState(null)
	const [reload, setReload] = useState(true)

	useEffect(() => {
		reload && getOffer(id, {
			include: 'user,medias,comments,categories,tags'
		})
			.then(res => setOffer(res.data.data))
			.catch(error => handleError(error))
			.finally(() => setReload(false))
	}, [ reload, id ])

	if(!offer) return <Spinner />

	const morphProps = {
		model: offer,
		modelType: "offer",
		reload: () => setReload(true)
	}

	return (
		<React.Fragment>
			<Row>
				<Col md="4">
					<Card>
						<CardHeader>
							<h6 className='mb-0'>Detalles Generales</h6>
						</CardHeader>
						<CardBody>
							<OfferDetailsWidget offer={offer} reload={() => setOffer(null)} />
						</CardBody>
					</Card>
					<Card>
						<CardHeader>
							<h6 className='mb-0'>Categorías de Clasificados</h6>
						</CardHeader>
						<CardBody>
							<ModelCategoriesWidget {...morphProps} />
						</CardBody>
					</Card>
					<Card>
						<CardHeader>
							<h6 className='mb-0'>Etiquetas de Clasificados</h6>
						</CardHeader>
						<CardBody>
							<ModelTagsWidget {...morphProps} />
						</CardBody> 
					</Card>
				</Col>
				<Col>
					<Card>
						<CardHeader>
							<h6 className='mb-0'>Contenido del Clasificado</h6>
						</CardHeader>
						<CardBody>
							<OfferContentWidget offer={offer} />
						</CardBody>
					</Card>
					<Card>
						<CardHeader>
							<h6 className='mb-0'>Multimedia</h6>
						</CardHeader>
						<CardBody>
							<ModelMediasWidget {...morphProps} />
						</CardBody>
					</Card>
					<Card>
						<CardHeader>
							<h6 className='mb-0'>Comentarios</h6>
						</CardHeader>
						<CardBody>
							<ModelCommentsWidget {...morphProps} />
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}