import springApi from '../../services/springApi'

export const getOpens = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/opens', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getOpen = (id, params) => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/opens/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeOpen = data => (
	new Promise((resolve, reject) => {
		springApi()
			.open('/opens', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateOpen = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/opens/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteOpen = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/opens/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)