import springApi, { getDomain } from '../../services/springApi'

export const getDepartments = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/departments', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getDepartment = (id, params) => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/departments/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeDepartment = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/departments', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateDepartment = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/departments/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteDepartment = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/departments/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchDepartments = async (name, token) => (
	fetch( getDomain()+`/departments?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(department => ({
				label: `${department.name} (${department.group_name})`,
				value: department.id,
			})),
		)
)