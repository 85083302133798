import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

import DepartmentsTable from './partials/DepartmentsTable'
import NewDepartmentModal from './partials/NewDepartmentModal'

import { handleError } from '../../helpers'
import { getDepartments } from './services'

export default function DepartmentsListModal({ visible, onClose, reload }) {
	const [departments, setDepartments] = useState(null)
	const [showNewModal, setShowNewModal] = useState(false)

	useEffect(() => {
		!departments && getDepartments({ include: 'usersCount' })
			.then(res => setDepartments(res.data.data))
			.catch(error => handleError(error))
	}, [ departments ])

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Listado de Departamentos</ModalHeader>
				<ModalBody>
					<DepartmentsTable 
						departments={departments}
						reload={reload}
					/>
					<Button color="primary" onClick={() => setShowNewModal(true)}>
						Crear Departamento
					</Button>
				</ModalBody>
			</Modal>
			{ showNewModal && (
				<NewDepartmentModal 
					visible
					onClose={() => setShowNewModal(false)}
					reload={() => {
						setDepartments(null)
						setShowNewModal(false)
					}}
				/>
			)}
		</React.Fragment>
	)
}