import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'

import GiftCardsTable from './partials/GiftCardsTable'

import { handleError, parsePagination } from '../../helpers'
import { getGiftCards } from './services'
import ImportGiftCardsModal from './partials/ImportGiftCardsModal'

export default function GiftCardsList() {
	const [giftCards, setGiftCards] = useState(null)
	const [filterType, setFilterType] = useState('user.document')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const [showImportModal, setShowImportModal] = useState(false)

	useEffect(() => {
		!giftCards && getGiftCards({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			'filter[type]': 'birthday',
			include: 'user'
		})
			.then(res => {
				setGiftCards(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ giftCards, pagination, filterType, filterValue ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Bonos de Regalo Cumpleaños"
				subtitle="Listado de todos los bonos registrados en la plataforma"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setGiftCards(null)}
				options={[
					{ label: 'Buscar por cedula del usuario', value: 'user.document' },
					{ label: 'Buscar por nombre del usuario', value: 'user.name' },
					{ label: 'Buscar por apellido del usuario', value: 'user.lastname' },
				]}
				ctaText="Importar Bonos"
				handleCtaClick={() => setShowImportModal(true)}
			/>
			<Card>
				<CardBody>
					<GiftCardsTable 
						giftCards={giftCards}
						pagination={pagination}
						setPagination={setPagination}
						reload={() => setGiftCards(null)}
					/>
				</CardBody>
			</Card>
			{ showImportModal && (
				<ImportGiftCardsModal 
					visible
					onClose={() => setShowImportModal(false)}
					reload={() => setGiftCards(null)}
				/>
			)}
		</React.Fragment>
	)
}