import React, { useState } from 'react'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckDouble, faEdit, faQuestionCircle, faTrash } from '@fortawesome/free-solid-svg-icons'

import EditSurveyModal from './EditSurveyModal'

import Pagination from '../../../components/Pagination'
import SurveyQuestionsModal from '../../Questions/SurveyQuestionsModal'
import SurveyResponsesModal from '../../SurveyResponses/SurveyResponsesModal'

import { deleteSurvey } from '../services'
import { handleError } from '../../../helpers'

export default function SurveysTable({ surveys, reload, pagination, setPagination }) {
	const [editModal, setEditModal] = useState(null)
	const [questionsModal, setQuestionsModal] = useState(null)
	const [responsesModal, setResponsesModal] = useState(null)
	
	const columns = [
		{
			title: 'Título',
			dataIndex: 'name'
		},
		{
			title: 'Preguntas',
			dataIndex: 'questions_count',
			render: t => `${t} preguntas`
		},
		{
			title: 'Respuestas',
			dataIndex: 'survey_responses_count',
			render: t => `${t} respuestas`
		},
		{
			title: 'Estado',
			dataIndex: 'active',
			render: t => t ? 'Activa' : 'Inactiva'
		},
		{
			title: 'Acciones',
			render: (t,r) => (
				<React.Fragment>
					<Tooltip title="Preguntas" onClick={()=>setQuestionsModal(r)}>
						<FontAwesomeIcon className="text-link" icon={faQuestionCircle}	/>{" "}
						<span className='text-link'>Preguntas</span>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Respuestas" onClick={()=>setResponsesModal(r)}>
						<FontAwesomeIcon className="text-link" icon={faCheckDouble}	/>{" "}
						<span className='text-link'>Respuestas</span>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Editar" onClick={()=>setEditModal(r)}>
						<FontAwesomeIcon className="text-link" icon={faEdit} />{" "}
						<span className='text-link'>Editar</span>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Eliminar">
						<Popconfirm
							title="Esta acción no puede ser revertida"
							okText="Eliminar"
							cancelText="Cancelar"
							onConfirm={() => handleDelete(r.id)}
							okButtonProps={{ danger:true }}
						>
							<FontAwesomeIcon className="text-link" icon={faTrash} />{" "}
							<span className='text-link'>Eliminar</span>
						</Popconfirm>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	const handleDelete = id => {
		deleteSurvey(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}
	
	return (
		<React.Fragment>
			<Table 
				dataSource={surveys}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				size="small"
				locale={{ emptyText: (
					<Empty 
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				updatePagination={setPagination}
				reload={reload}
			/>
			{ editModal && (
				<EditSurveyModal 
					visible
					onClose={() => setEditModal(null)}
					reload={() => {
						reload()
						setEditModal(null)
					}}
					survey={editModal}
				/>
			)}
			{ questionsModal && (
				<SurveyQuestionsModal 
					visible
					onClose={() => {
						setQuestionsModal(null)
						reload()
					}}
					survey={questionsModal}
				/>
			)}
			{ responsesModal && (
				<SurveyResponsesModal 
					visible
					onClose={() => setResponsesModal(null)}
					survey={responsesModal}
				/>
			)}
		</React.Fragment>
	)
}