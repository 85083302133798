import springApi, { getDomain } from '../../services/springApi'

export const getTags = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/tags', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getTag = (id, params) => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/tags/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeTag = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/tags', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateTag = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/tags/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteTag = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/tags/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const toggleTag = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/tags/toggle', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchTags = async (name, token) => (
	fetch( getDomain()+`/tags?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(tag => ({
				label: tag.name,
				value: tag.id,
			})),
		)
)