import React from 'react'
import { Form } from 'react-bootstrap'

export default function DepartmentForm({ register, errors }) {
	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Nombre <span className="text-danger">*</span></Form.Label>
				<Form.Control {...register('name', { required:true })} />
				{ errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Grupo <span className="text-danger">*</span></Form.Label>
				<Form.Control {...register('group_name', { required:true })} />
				{ errors.group_name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
		</React.Fragment>
	)
}