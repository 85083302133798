import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'
import moment from 'moment'

import UserForm from './UserForm'

import { updateUser } from '../services'
import { handleError } from '../../../helpers'

export default function EditUserModal({ visible, onClose, reload, user }) {
	const [selectedDepartment, setSelectedDepartment] = useState({
		value: user.department_id,
		label: user.department_name,
	})
	const { register, handleSubmit, formState: { errors } } = useForm({
		defaultValues: { 
			...user,
			born_at: moment(user.born_at).format('YYYY-MM-DD'),
			hire_at: moment(user.hire_at).format('YYYY-MM-DD'),
		}
	})

	const onSubmit = values => {
		values.department_id = selectedDepartment.value
		updateUser(user.id, values)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Actualizar Registro</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<UserForm 
							register={register} 
							errors={errors} 
							user={user} 
							selectedDepartment={selectedDepartment}
							setSelectedDepartment={setSelectedDepartment}
						/>
						<Button color="primary" type="submit">Actualizar Registrar</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}