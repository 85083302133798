import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'
import moment from 'moment'

import RewardForm from './RewardForm'

import { updateReward } from '../services'
import { handleError } from '../../../helpers'

export default function EditRewardModal({ visible, onClose, reload, reward }) {
	const { register, handleSubmit, formState: { errors } } = useForm({
		defaultValues: { 
			name: reward.name,
			hint: reward.hint,
			given_at: moment(reward.given_at).format('YYYY-MM-DD'),
			is_active: reward.is_active ? 1 : 0,
		}
	})

	const onSubmit = values => {
		updateReward(reward.id, values)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Actualizar Registro</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<RewardForm register={register} errors={errors} reward={reward} />
						<Button color="primary" type="submit">Actualizar Registrar</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}