import springApi, { getDomain } from '../../services/springApi'

export const getOffers = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/offers', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getOffer = (id, params) => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/offers/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeOffer = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/offers', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateOffer = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/offers/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteOffer = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/offers/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchOffers = async (name, token) => (
	fetch( getDomain()+`/offers?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(offer => ({
				label: offer.name,
				value: offer.id,
			})),
		)
)