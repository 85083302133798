import springApi, { getDomain } from '../../services/springApi'

export const getPosts = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/posts', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getPost = (id, params) => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/posts/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storePost = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/posts', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updatePost = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/posts/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deletePost = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/posts/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchPosts = async (name, token) => (
	fetch( getDomain()+`/posts?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(post => ({
				label: post.name,
				value: post.id,
			})),
		)
)

export const toggleDepartment = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/department_post', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const toggleOnAllDepartments = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/department_post/all_on', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)