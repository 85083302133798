import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'

import NewContestModal from './partials/NewContestModal'
import ContestsTable from './partials/ContestsTable'

import { getContests } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function ContestsList() {
	const [contests, setContests] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const [showNewModal, setShowNewModal] = useState(false)

	useEffect(() => {
		!contests && getContests({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			include: 'subscribersCount,opensCount,winnerUser,departments'
		})
			.then(res => {
				setContests(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ contests, pagination, filterType, filterValue ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Concursos Internos"
				subtitle="Listado de todos los concursos publicados en la plataforma"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setContests(null)}
				options={[
					{ label: 'Buscar por nombre', value: 'name' },
					{ label: 'Buscar por descrición', value: 'description' },
				]}
				ctaText="Agregar Concurso"
				handleCtaClick={() => setShowNewModal(true)}
			/>
			<Card>
				<CardBody>
					<ContestsTable 
						contests={contests}
						reload={() => setContests(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
			{ showNewModal && (
				<NewContestModal 
					visible
					onClose={() => setShowNewModal(false)}
					reload={() => {
						setContests(null)
						setShowNewModal(false)
					}}
				/>
			)}
		</React.Fragment>
	)
}