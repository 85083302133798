import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'

import NewSurveyModal from './partials/NewSurveyModal'
import SurveysTable from './partials/SurveysTable'

import { handleError, parsePagination } from '../../helpers'
import { getSurveys } from './services'

export default function SurveysList() {
	const [surveys, setSurveys] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const [showNewModal, setShowNewModal] = useState(false)

	useEffect(() => {
		!surveys && getSurveys({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			'filter[active]': 1,
			include: 'questionsCount,surveyResponsesCount'
		})
			.then(res => {
				setSurveys(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ surveys, pagination, filterType, filterValue ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Listado de Encuestas"
				subtitle="Listado de todos las encuestas publicados en la plataforma"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setSurveys(null)}
				options={[
					{ label: 'Buscar por nombre', value: 'name' },
				]}
				ctaText="Agregar Encuesta"
				handleCtaClick={() => setShowNewModal(true)}
			/>
			<Card>
				<CardBody>
					<SurveysTable 
						surveys={surveys}
						reload={() => setSurveys(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
			{ showNewModal && (
				<NewSurveyModal 
					visible
					onClose={() => setShowNewModal(false)}
					reload={() => {
						setSurveys(null)
						setShowNewModal(false)
					}}
				/>
			)}
		</React.Fragment>
	)
}