import React from 'react'
import { Form } from 'react-bootstrap'

export default function QuestionForm({ register, errors, question }) {
	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Pregunta <span className="text-danger">*</span></Form.Label>
				<Form.Control {...register('name', { required:true })} />
				{ errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Frase de Ayuda <small>(opcional)</small></Form.Label>
				<Form.Control {...register('description', {})} />
			</Form.Group>
			<Form.Group>
				<Form.Label>Estado <span className="text-danger">*</span></Form.Label>
				<Form.Control
					as="select"
					{...register('active', { required: true })}
				>
					<option value="">:: Seleccionar una opción ::</option>
					<option value="1">Activa</option>
					<option value="0">Inactiva</option>
				</Form.Control>
				{ errors.active && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
		</React.Fragment>
	)
}