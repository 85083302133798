import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Alert, Button, Card, CardBody, Col, Row } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { Divider, message } from 'antd'

import { sendPushNotification } from './services'
import { handleError } from '../../helpers'

export default function SendPushNotification() {
	const { register, handleSubmit, formState: { errors }, watch, reset } = useForm()
	const [loading, setLoading] = useState(false)

	const onSubmit = values => {
		setLoading(true)
		sendPushNotification(values)
			.then(res => {
				message.success(res.data.message)
				reset()
			})
			.catch(error => handleError(error))
			.finally(() => setLoading(false))
	}

	return (
		<React.Fragment>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Row>
					<Col lg="8" md="10">
						{/* <Card>
							<CardBody>
								<strong>1. Usuarios seleccionados</strong>
								<Divider className='my-2' />
							</CardBody>
						</Card> */}
						<Card>
							<CardBody>
								<strong>1. Contenido de la notificación</strong>
								<Divider className='my-2' />
								<Form.Group>
									<Form.Label>Titular <span className='text-danger'>*</span></Form.Label>
									<Form.Control 
										{...register('title', { required:true, maxLength: 65 })}
										placeholder="Escriba aquí..."
									/>
									<Form.Text className='hint-text'>{watch('title') ? watch('title').length : 0} de 65 permitidos</Form.Text>
									{ errors.title && <Form.Text className='text-warning'>Este campo es requerido y debe ser menor a 65 caracteres</Form.Text> }
								</Form.Group>
								<Form.Group>
									<Form.Label>Mensaje <span className='text-danger'>*</span></Form.Label>
									<Form.Control 
										as="textarea"
										{...register('message', { required:true, maxLength: 170 })}
										placeholder="Escriba aquí..."
									/>
									<Form.Text className='hint-text'>{watch('message') ? watch('message').length : 0} de 170 permitidos</Form.Text>
									{ errors.message && <Form.Text className='text-warning'>Este campo es requerido y debe ser menor a 170 caracteres</Form.Text> }
								</Form.Group>
							</CardBody>
						</Card>
						<Card>
							<CardBody>
								<strong>2. Confirmación y envío</strong>
								<Divider className='my-2' />
								<Alert color='info'>Se enviara a todos los dispositivos de los usuarios que hayan aprobado el permiso de notificaciones para la App</Alert>
								<Button color="primary" type="submit" disabled={loading}>
									Enviar Notificación
								</Button>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Form>
		</React.Fragment>
	)
}