import React, { useState, useEffect } from 'react'
import { Upload, Modal, message } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { Spinner } from 'reactstrap'
import AWS from "aws-sdk"

import { deleteMedia, storeMedia } from './services'
import { handleError } from '../../helpers'

export default function ModelMediasWidget({ model, modelType, reload }) {
	const [previewVisible, setPreviewVisible] = useState(false)
	const [previewImage, setPreviewImage] = useState('')
	const [fileList, setFileList] = useState(null)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		let newList = model.medias.map( media => ({
			uid: media.id,
			status: 'done',
			url: media.url,
		}))
		setFileList(newList)
	}, [ model ])

	if(!fileList) return <Spinner size="small" />
 
	const handlePreview = async file => {
		setPreviewImage(file.url || file.preview)
		setPreviewVisible(true)
	}

	const handleDelete = file => {
		deleteMedia(file.uid)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const props = {
		fileList,
		listType: 'picture-card',
		onPreview: handlePreview,
		onRemove: handleDelete,
		customRequest({
			file,
			onError,
			onProgress,
			onSuccess,
		}) {
			AWS.config.update({
				accessKeyId: 'AKIA4NB262CUC7DWBVF3',
    			secretAccessKey: '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'
			});
		
			const S3 = new AWS.S3({
				params: { Bucket: 'springfamily' },
				region: 'us-east-1',
		  	});

		  	let fileExt = file.name.split('.').pop();
			let today = new Date()
			let uri = `app/medias/${modelType}/${model.id}/${today.getTime()}.${fileExt}`
		
			const objParams = {
				Bucket: "springfamily",
				Key: uri,
				Body: file,
				ContentType: file.type
			};

			setLoading(true)
		
			S3.putObject(objParams)
				.on("httpUploadProgress", function({ loaded, total }) {
					onProgress(
						{ percent: Math.round((loaded / total) * 100) },
						file
					);
				})
				.send(function(err, data) {
					if (err) {
						onError()
						console.error('Error al subir imagen: ', err.message)
						setLoading(false)
					} else {
						onSuccess(data.response, file);						
						storeMedia({
							attachable_type: modelType,
							attachable_id: model.id,
							url: `https://springfamily.s3.us-east-1.amazonaws.com/${uri}`
						})
							.then(res => {
								message.success(res.data.message)
								setLoading(false)
								reload()
							})
							.catch(error => {
								setLoading(false)
								handleError(error)
							})
					}
				})
		}
	}

   return (
      <React.Fragment>
        	<Upload {...props} disabled={loading}>
          	{fileList.length <= 5 && (
					<div>
						{!loading ? <PlusOutlined /> : <Spinner size="small" />} 
						<div className='mt-2'>Upload</div>
					</div>
				)}
        	</Upload>
        	<Modal
				visible={previewVisible}
				title="Vista Previa"
				footer={null}
				onCancel={() => setPreviewVisible(false)}
        	>
          	<img alt="vista_previa" style={{ width: '100%' }} src={previewImage} />
        	</Modal>
      </React.Fragment>
   );
}