import springApi, { getDomain } from '../../services/springApi'

export const getRewards = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/rewards', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getReward = (id, params) => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/rewards/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeReward = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/rewards', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateReward = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/rewards/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteReward = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/rewards/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchRewards = async (name, token) => (
	fetch( getDomain()+`/rewards?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(reward => ({
				label: reward.name,
				value: reward.id,
			})),
		)
)