import springApi, { getDomain } from '../../services/springApi'

export const getContests = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/contests', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getContest = (id, params) => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/contests/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeContest = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/contests', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateContest = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/contests/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteContest = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/contests/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchContests = async (name, token) => (
	fetch( getDomain()+`/contests?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(contest => ({
				label: contest.name,
				value: contest.id,
			})),
		)
)

export const toggleDepartment = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/department_contest', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const toggleOnAllDepartments = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/department_contest/all_on', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)