import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import OpensList from './OpensList'

export default function Opens() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/opens" component={OpensList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}