import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import SurveyResponsesTable from './partials/SurveyResponsesTable'

import { getSurveyResponses } from './services'
import { handleError } from '../../helpers'

export default function SurveyResponsesModal({ visible, onClose, survey }) {
	const [surveyResponses, setSurveyResponses] = useState(null)

	useEffect(() => {
		!surveyResponses && getSurveyResponses({
			'filter[survey_id]': survey.id,
			include: 'user,answers.question'
		})
			.then(res => setSurveyResponses(res.data.data))
			.catch(error => handleError(error))
	}, [ surveyResponses, survey ])

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose} size="lg">
				<ModalHeader toggle={onClose}>Respuestas Recibidas</ModalHeader>
				<ModalBody>
					<SurveyResponsesTable 
						surveyResponses={surveyResponses}
						reload={() => setSurveyResponses(null)}
					/>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}