import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import ContestForm from './ContestForm'

import { storeContest } from '../services'
import { handleError } from '../../../helpers'

export default function NewContestModal({ visible, onClose, reload }) {
	const { register, handleSubmit, formState: { errors } } = useForm({})

	const onSubmit = values => {
		storeContest(values)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Agregar Registro</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<ContestForm register={register} errors={errors} />
						<Button color="primary" type="submit">Agregar Registro</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}