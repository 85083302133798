import React, { useState, useEffect } from 'react'
import { Spinner } from 'reactstrap'
import { Divider, Empty } from 'antd'

import NewTagForm from './partials/NewTagForm'
import TagItem from './partials/TagItem'

import { getTags } from './services'
import { handleError } from '../../helpers'

export default function ModelTagsWidget({ model, modelType, reload }) {
	const [tags, setTags] = useState(null)

	useEffect(() => {
		!tags && getTags({
			'filter[group]': modelType
		})
			.then(res => setTags(res.data.data))
			.catch(error => handleError(error))
	}, [ tags, modelType ])

	if(!tags) return <Spinner size="sm" />

	return (
		<React.Fragment>
			{ tags.length > 0 ? (
				<React.Fragment>
					{ tags.map( tag => (
						<TagItem 
							key={tag.id}
							tag={tag}
							model={model}
							modelType={modelType}
							reload={reload}
							reloadTags={() => setTags(null)}
						/>
					)) }
				</React.Fragment>
			) : (
				<Empty 
					description="No se han creado etiquetas aún"
					image={Empty.PRESENTED_IMAGE_SIMPLE}
				/>
			)}
			<Divider className='mt-2 mb-2' />
			<NewTagForm 
				modelType={modelType}
				reload={() => setTags(null)}
			/>
		</React.Fragment>
	)
}