import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Col, Row, Badge } from 'reactstrap'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faEdit, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import EditPostModal from './EditPostModal'
import DepartmentsAttachedModal from '../../Departments/DepartmentsAttachedModal'

import Pagination from '../../../components/Pagination'

import { deletePost, toggleDepartment, toggleOnAllDepartments } from '../services'
import { handleError } from '../../../helpers'

export default function PostsTable({ posts, reload, pagination, setPagination }) {
	const [editModal, setEditModal] = useState(null)
	const [departmentsModal, setDepartmentsModal] = useState(null)
	
	const columns = [
		{
			title: 'Título',
			dataIndex: 'name',
			render: (t,r) => <Link to={`/posts/${r.id}`}>{t}</Link>
		},
		{
			title: 'Autor',
			dataIndex: 'user',
			render: t => t.fullname
		},
		{
			title: 'Vence el',
			dataIndex: 'expire_at',
			render: t => moment(t).format('YYYY-MM-DD')
		},
		{
			title: 'Estado',
			dataIndex: 'is_published',
			render: t => t ? <Badge color='success'>Publicado</Badge> : <Badge color="warning">Borrador</Badge>,
		},
		{
			title: 'Aperturas',
			dataIndex: 'opens_count',
			render: t => `${t} en total`
		},
		{
			title: 'Reacciones',
			dataIndex: 'reactions_summary',
			render: t => `${t.likes_count} 👍 | ${t.hearts_count} ❤️ | ${t.claps_count} 👏`
		},
		{
			title: 'Acciones',
			render: (t,r) => (
				<React.Fragment>
					<Tooltip title="Ver">
						<Link to={`/posts/${r.id}`}>
							<FontAwesomeIcon 
								className="text-link" 
								icon={faSearch}
							/>{" "}
							<span className='text-link'>Ver</span>	
						</Link>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Departamentos Asociados" onClick={()=>setDepartmentsModal(r)}>
						<FontAwesomeIcon 
							className="text-link" 
							icon={faBuilding}
						/>{" "}
						<span className='text-link'>Depart.</span>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Editar" onClick={()=>setEditModal(r)} >
						<FontAwesomeIcon 
							className="text-link" 
							icon={faEdit}
						/>{" "}
						<span className='text-link'>Editar</span>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Eliminar">
						<Popconfirm
							title="Esta acción no puede ser revertida"
							okText="Eliminar"
							cancelText="Cancelar"
							onConfirm={() => handleDelete(r.id)}
							okButtonProps={{ danger:true }}
						>
							<FontAwesomeIcon className="text-link" icon={faTrash} />{" "}
							<span className='text-link'>Eliminar</span>
						</Popconfirm>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	const handleDelete = id => {
		deletePost(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const handleToggleDepartment = departmentId => {
		toggleDepartment({ 
			post_id: departmentsModal.id, 
			department_id: departmentId 
		})
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const handleToggleOnAllDepartments = () => {
		toggleOnAllDepartments({ 
			post_id: departmentsModal.id, 
		})
			.then(res => {
				message.success(res.data.message)
				reload()
				setDepartmentsModal(null)
			})
			.catch(error => handleError(error))
	}

	const expandedRowRender = r => (
		<React.Fragment>
			<Row>
				<Col>
					<p className='mb-2'>
						<strong>Categorias: </strong>
						{r.categories.length > 0 ? r.categories.map(c => `${c.name} | `) : <em className='hint-text'>Sin categorías</em>}
					</p>
				</Col>
				<Col>
					<p className='mb-0'>
						<strong>Etiquetas: </strong>
						{r.tags.length > 0 ? r.tags.map(c => `${c.name} | `) : <em className='hint-text'>Sin etiquetas</em>}
					</p>
				</Col>
			</Row>
			<p className='mb-2'><strong>Descripción Corta: </strong>{r.excerpt}</p>
			<p className='mb-2'><strong>Contenido: </strong>{r.description}</p>
		</React.Fragment>
	)
	
	return (
		<React.Fragment>
			<Table 
				dataSource={posts}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				size="small"
				expandable={{ expandedRowRender }}
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				updatePagination={setPagination}
				reload={reload}
			/>
			{ editModal && (
				<EditPostModal 
					visible
					onClose={() => setEditModal(null)}
					reload={() => {
						reload()
						setEditModal(null)
					}}
					post={editModal}
				/>
			)}
			{ departmentsModal && (
				<DepartmentsAttachedModal 
					visible
					onClose={() => setDepartmentsModal(null)}
					departmentsAttached={departmentsModal.departments}
					onToggle={departmentId => handleToggleDepartment(departmentId)}
					onToggleOnAll={handleToggleOnAllDepartments}
				/>
			)}
		</React.Fragment>
	)
}