import React, { useState } from 'react'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

import EditQuestionModal from './EditQuestionModal'

import { deleteQuestion } from '../services'
import { handleError } from '../../../helpers'

export default function QuestionsTable({ questions, reload }) {
	const [editModal, setEditModal] = useState(null)
	
	const columns = [
		{ 
			title: 'Pregunta',
			dataIndex: 'name'
	 	},
		{
			title: 'Frase de Ayuda',
			dataIndex: 'description',
			render: t => t ? t : <em className='hint-text'>Sin descripción</em>
		},
		{
			title: 'Estado',
			dataIndex: 'active',
			render: t => t ? 'Activa' : 'Inactiva'
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Editar">
						<FontAwesomeIcon 
							className="text-link" 
							icon={faEdit} 
							onClick={()=>setEditModal(r)} 
						/>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Eliminar">
						<Popconfirm
							title="Esta acción no puede ser revertida"
							okText="Eliminar"
							cancelText="Cancelar"
							onConfirm={() => handleDelete(r.id)}
							okButtonProps={{ danger:true }}
						>
							<FontAwesomeIcon className="text-link" icon={faTrash} />
						</Popconfirm>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	const handleDelete = id => {
		deleteQuestion(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}
	
	return (
		<React.Fragment>
			<Table 
				dataSource={questions}
				rowKey={r => r.key}
				columns={columns}
				size="small"
				locale={{ emptyText: (
					<Empty 
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			{ editModal && (
				<EditQuestionModal 
					visible
					onClose={() => setEditModal(null)}
					reload={reload}
					question={editModal}
				/>
			)}
		</React.Fragment>
	)
}