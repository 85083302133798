import React, { useState } from 'react'
import { Table, Tooltip, Divider, Popconfirm, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

import EditDepartmentModal from './EditDepartmentModal'

import { deleteDepartment } from '../services'
import { handleError } from '../../../helpers'

export default function DepartmentsTable({ departments, reload }) {
	const [editModal, setEditModal] = useState(null)
	
	const columns = [
		{
			title: 'Departamento',
			dataIndex: 'name'
		},
		{
			title: 'Grupo',
			dataIndex: 'group_name',
		},
		{
			title: 'Usuarios',
			dataIndex: 'users_count',
			render: t => `${t} usuarios`
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Editar">
						<FontAwesomeIcon 
							className="text-link" 
							icon={faEdit} 
							onClick={()=>setEditModal(r)} 
						/>
					</Tooltip>
					{ r.users_count === 0 && (
						<React.Fragment>
							<Divider type='vertical' />
							<Tooltip title="Eliminar">
								<Popconfirm
									title="Esta acción no puede ser revertida"
									okText="Eliminar"
									cancelText="Cancelar"
									onConfirm={() => handleDelete(r.id)}
									okButtonProps={{ danger:true }}
								>
									<FontAwesomeIcon className="text-link" icon={faTrash} />
								</Popconfirm>
							</Tooltip>
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]

	const handleDelete = id => {
		deleteDepartment(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}
	
	return (
		<React.Fragment>
			<Table 
				dataSource={departments}
				rowKey={r => r.id}
				columns={columns}
				size="small"
				loading={!departments}
			/>
			{ editModal && (
				<EditDepartmentModal 
					visible
					department={editModal}
					onClose={() => setEditModal(null)}
					reload={() => {
						reload()
						setEditModal(null)
					}}
				/>
			)}
		</React.Fragment>
	)
}