import React, { useState } from 'react'
import { Descriptions } from 'antd'
import { Badge, Button } from 'reactstrap'
import moment from 'moment'
import EditPostModal from './EditPostModal'

const { Item } = Descriptions

export default function PostDetailsWidget({ post, reload }) {
	const [showEditModal, setShowEditModal] = useState(false)

	return (
		<React.Fragment>
			<Descriptions size="small" column={1}>
				<Item>
					{post.is_published ? <Badge color='success'>Publicado</Badge> : <Badge color='warning'>Borrador</Badge>}
				</Item>
				<Item label="Título">{post.name}</Item>
				<Item label="Resumen">{post.excerpt}</Item>
				<Item label="ID Publicación">#00{post.id}</Item>
				<Item label="Fecha Creación">{moment(post.created_at).format('YYYY-MM-DD HH:mm')}</Item>
				<Item label="Fecha Última Modificación">{moment(post.updated_at).format('YYYY-MM-DD HH:mm')}</Item>
			</Descriptions>
			<div className='mt-2'>
				<Button color="primary" outline onClick={() => setShowEditModal(true)}>
					Actualizar Datos
				</Button>
			</div>
			{ showEditModal && (
				<EditPostModal
					visible
					onClose={() => setShowEditModal(false)}
					post={post}
					reload={() => {
						setShowEditModal(false)
						reload()
					}}
				/>
			)}
		</React.Fragment>
	)
}