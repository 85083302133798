import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Badge } from 'reactstrap'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faEdit, faImage, faSearch, faTrash, faTrophy } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import EditContestModal from './EditContestModal'
import WinnerModal from './WinnerModal'

import Pagination from '../../../components/Pagination'
import UploadImageModal from '../../../components/UploadImageModal'
import DepartmentsAttachedModal from '../../Departments/DepartmentsAttachedModal'

import { deleteContest, toggleDepartment, toggleOnAllDepartments, updateContest } from '../services'
import { handleError } from '../../../helpers'

export default function ContestsTable({ contests, reload, pagination, setPagination }) {
	const [editModal, setEditModal] = useState(null)
	const [imageModal, setImageModal] = useState(null)
	const [winnerModal, setWinnerModal] = useState(null)
	const [departmentsModal, setDepartmentsModal] = useState(null)
	
	const columns = [
		{
			title: 'Nombre',
			dataIndex: 'name',
			render: (t,r) => <Link to={`/contests/${r.id}`}>{t}</Link>
		},
		{
			title: 'Estado',
			dataIndex: 'is_published',
			render: t => t ? <Badge color='success'>Publicado</Badge> : <Badge color="warning">Borrador</Badge>,
		},
		{
			title: 'Fecha Creación',
			dataIndex: 'created_at',
			render: t => moment(t).format('YYYY-MM-DD HH:mm')
		},
		{
			title: 'Vencimiento',
			dataIndex: 'expire_at',
			render: t => moment(t).format('YYYY-MM-DD')
		},
		{
			title: 'Ganador',
			dataIndex: 'winner_user_id',
			render: t => t ? <Badge color='info'>Elegido</Badge> : <Badge color="warning">Sin Elegir</Badge>,
		},
		{
			title: 'Aperturas',
			dataIndex: 'opens_count',
			render: t => `${t} en total`
		},
		{
			title: 'Inscritos',
			dataIndex: 'subscribers_count',
			render: t => `${t} inscritos`
		},
		{
			title: 'Acciones',
			render: (t,r) => (
				<React.Fragment>
					<Tooltip title="Ver">
						<Link to={`/contests/${r.id}`}>
							<FontAwesomeIcon 
								className="text-link" 
								icon={faSearch}
							/>
						</Link>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Imágen Principal" onClick={()=>setImageModal(r)}>
						<FontAwesomeIcon 
							className="text-link"
							icon={faImage}
						/>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Usuario Ganador" onClick={()=>setWinnerModal(r)}>
						<FontAwesomeIcon 
							className="text-link"
							icon={faTrophy}
						/>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Departamentos Asociados" onClick={()=>setDepartmentsModal(r)}>
						<FontAwesomeIcon 
							className="text-link" 
							icon={faBuilding}
						/>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Editar" onClick={()=>setEditModal(r)} >
						<FontAwesomeIcon 
							className="text-link" 
							icon={faEdit} 							
						/>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Eliminar">
						<Popconfirm
							title="Esta acción no puede ser revertida"
							okText="Eliminar"
							cancelText="Cancelar"
							onConfirm={() => handleDelete(r.id)}
							okButtonProps={{ danger:true }}
						>
							<FontAwesomeIcon className="text-link" icon={faTrash} />
						</Popconfirm>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	const handleDelete = id => {
		deleteContest(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const onImageSubmit = uri => {
		updateContest(imageModal.id, {
			'cover_image_url': `https://springfamily.s3.us-east-1.amazonaws.com/${uri}`
		})
			.then(res => {
				message.success(res.data.message)
				reload()
				setImageModal(null)
			})
			.catch(error => handleError(error))
	}

	const handleToggleDepartment = departmentId => {
		toggleDepartment({ 
			contest_id: departmentsModal.id, 
			department_id: departmentId 
		})
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const handleToggleOnAllDepartments = () => {
		toggleOnAllDepartments({ 
			contest_id: departmentsModal.id, 
		})
			.then(res => {
				message.success(res.data.message)
				reload()
				setDepartmentsModal(null)
			})
			.catch(error => handleError(error))
	}
	
	return (
		<React.Fragment>
			<Table 
				dataSource={contests}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				size="small"
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				updatePagination={setPagination}
				reload={reload}
			/>
			{ editModal && (
				<EditContestModal
					visible
					onClose={() => setEditModal(null)}
					reload={() => {
						reload()
						setEditModal(null)
					}}
					contest={editModal}
				/>
			)}
			{ imageModal && (
				<UploadImageModal 
					visible
					onClose={() => setImageModal(null)}
					directory="contests"
					preview={imageModal.cover_image_url}
					filename={imageModal.id}
					onSuccess={onImageSubmit}
					onError={() => message.error('No se pudo cargar la imagen')}
				/>
			)}
			{ winnerModal && (
				<WinnerModal 
					visible
					onClose={() => setWinnerModal(null)}
					contest={winnerModal}
					reload={reload}
				/>
			)}
			{ departmentsModal && (
				<DepartmentsAttachedModal 
					visible
					onClose={() => setDepartmentsModal(null)}
					departmentsAttached={departmentsModal.departments}
					onToggle={departmentId => handleToggleDepartment(departmentId)}
					onToggleOnAll={handleToggleOnAllDepartments}
				/>
			)}
		</React.Fragment>
	)
}