import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import QuestionForm from './QuestionForm'

import { updateQuestion } from '../services'
import { handleError } from '../../../helpers'

export default function EditQuestionModal({ visible, onClose, reload, question }) {
	const { register, handleSubmit, formState: { errors } } = useForm({
		defaultValues: { ...question }
	})

	const onSubmit = values => {
		updateQuestion(question.id, values)
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Actualizar Registro</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<QuestionForm register={register} errors={errors} question={question} />
						<Button color="primary" type="submit">Actualizar Registrar</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}