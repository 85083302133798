import React, { useState } from 'react'
import { Descriptions } from 'antd'
import { Badge, Button } from 'reactstrap'
import moment from 'moment'

import EditContestModal from './EditContestModal'

const { Item } = Descriptions

export default function ContestDetailsWidget({ contest, reload }) {
	const [showEditModal, setShowEditModal] = useState(false)

	return (
		<React.Fragment>
			{ contest.cover_image_url && (
				<img src={contest.cover_image_url} width="100%" alt="Foto perfil" className='mb-3' />
			)}
			<Descriptions size="small" column={1}>
				<Item>
					{contest.is_published ? <Badge color='success'>Publicado</Badge> : <Badge color='warning'>Borrador</Badge>}
				</Item>
				<Item label="Título">{contest.name}</Item>
				<Item label="ID Publicación">#00{contest.id}</Item>
				<Item label="Fecha Creación">{moment(contest.created_at).format('YYYY-MM-DD HH:mm')}</Item>
				<Item label="Fecha Última Modificación">{moment(contest.updated_at).format('YYYY-MM-DD HH:mm')}</Item>
			</Descriptions>
			<div className='mt-2'>
				<Button color="primary" outline onClick={() => setShowEditModal(true)}>
					Actualizar Datos
				</Button>
			</div>
			{ showEditModal && (
				<EditContestModal
					visible
					onClose={() => setShowEditModal(false)}
					contest={contest}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}