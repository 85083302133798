import React, { useState } from 'react'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faUsers } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import ResponseAnswersModal from '../../Answers/ResponseAnswersModal'

import { deleteSurveyResponse } from '../services'
import { handleError } from '../../../helpers'

export default function SurveyResponsesTable({ surveyResponses, reload }) {
	const [answersModal, setAnswersModal] = useState(null)
	
	const columns = [
		{
			title: 'Usuario',
			dataIndex: 'user',
			render: t => t.name
		},
		{
			title: 'Email',
			dataIndex: 'user',
			render: t => t.email
		},
		{
			title: 'Fecha de Respuesta',
			dataIndex: 'created_at',
			render: t => moment(t).format('YYYY-MM-DD')
		},
		{
			title: 'Acciones',
			render: (t,r) => (
				<React.Fragment>
					<Tooltip title="Respuestas" onClick={()=>setAnswersModal(r)} >
						<FontAwesomeIcon className="text-link" icon={faUsers} />{" "}
						<span className='text-link'>Ver Respuestas</span>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Eliminar">
						<Popconfirm
							title="Esta acción no puede ser revertida"
							okText="Eliminar"
							cancelText="Cancelar"
							onConfirm={() => handleDelete(r.id)}
							okButtonProps={{ danger:true }}
						>
							<FontAwesomeIcon className="text-link" icon={faTrash} />{" "}
							<span className='text-link'>Eliminar</span>
						</Popconfirm>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	const handleDelete = id => {
		deleteSurveyResponse(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}
	
	return (
		<React.Fragment>
			<Table 
				dataSource={surveyResponses}
				rowKey={r => r.id}
				columns={columns}
				size="small"
				locale={{ emptyText: (
					<Empty 
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			{ answersModal && (
				<ResponseAnswersModal 
					visible
					onClose={() => setAnswersModal(null)}
					surveyResponse={answersModal}
				/>
			)}
		</React.Fragment>
	)
}