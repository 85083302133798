import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

import QuestionsTable from './partials/QuestionsTable'
import NewQuestionModal from './partials/NewQuestionModal'

import { getQuestions } from './services'
import { handleError } from '../../helpers'

export default function SurveyQuestionsModal({ visible, onClose, survey }) {
	const [questions, setQuestions] = useState(null)
	const [showNewModal, setShowNewModal] = useState(false)

	useEffect(() => {
		!questions && getQuestions({
			'filter[survey_id]': survey.id 
		})
			.then(res => setQuestions(res.data.data))
			.catch(error => handleError(error))
	}, [ questions, survey ])

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose} size="lg">
				<ModalHeader toggle={onClose}>Preguntas: {survey.name}</ModalHeader>
				<ModalBody>
					<QuestionsTable 
						questions={questions} 
						reload={() => setQuestions(null)}
					/>
					<div className='text-right mt-2'>
						<Button color="primary" onClick={() => setShowNewModal(true)}>
							Agregar Pregunta Nueva
						</Button>
					</div>
				</ModalBody>
			</Modal>
			{ showNewModal && (
				<NewQuestionModal 
					visible
					onClose={() => setShowNewModal(false)}
					reload={() => setQuestions(null)}
					survey={survey}
				/>
			)}
		</React.Fragment>
	)
}