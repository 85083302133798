import springApi, { getDomain } from '../../services/springApi'

export const getMedias = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/medias', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getMedia = (id, params) => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/medias/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeMedia = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/medias', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateMedia = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/medias/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteMedia = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/medias/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchMedias = async (name, token) => (
	fetch( getDomain()+`/medias?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(media => ({
				label: media.name,
				value: media.id,
			})),
		)
)