import springApi from '../../services/springApi'

export const getGiftCards = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/gift_cards', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getGiftCard = (id, params) => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/gift_cards/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeGiftCard = data => (
	new Promise((resolve, reject) => {
		springApi()
			.gift_card('/gift_cards', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateGiftCard = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/gift_cards/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteGiftCard = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/gift_cards/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const importGiftCards = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/gift_cards/import', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)