import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'

import NewOfferModal from './partials/NewOfferModal'
import OffersTable from './partials/OffersTable'

import { getOffers } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function OffersList() {
	const [offers, setOffers] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const [showNewModal, setShowNewModal] = useState(false)

	useEffect(() => {
		!offers && getOffers({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			include: 'user,categories,tags,opensCount'
		})
			.then(res => {
				setOffers(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ offers, pagination, filterType, filterValue ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Clasificados Soy Spring"
				subtitle="Listado de todos los clasificados publicados en la plataforma"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setOffers(null)}
				options={[
					{ label: 'Buscar por título', value: 'name' },
					{ label: 'Buscar por descripción corta', value: 'excerpt' },
					{ label: 'Buscar por contenido', value: 'description' },
				]}
				ctaText="Agregar Clasificado"
				handleCtaClick={() => setShowNewModal(true)}
			/>
			<Card>
				<CardBody>
					<OffersTable 
						offers={offers}
						reload={() => setOffers(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
			{ showNewModal && (
				<NewOfferModal 
					visible
					onClose={() => setShowNewModal(false)}
					reload={() => {
						setOffers(null)
						setShowNewModal(false)
					}}
				/>
			)}
		</React.Fragment>
	)
}