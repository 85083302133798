import React, { useState } from 'react'
import { Alert, Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import ServerSideSelect from '../../../components/ServerSideSelect'

import { searchUsers } from '../../Users/services'
import { updateContest } from '../services'
import { handleError } from '../../../helpers'

export default function WinnerModal({ visible, onClose, contest, reload }) {
	const [selectedUser, setSelectedUser] = useState([])

	const onSubmit = () => {
		updateContest(contest.id, {
			'winner_user_id': selectedUser.value
		})
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Ganador del Concurso</ModalHeader>
				<ModalBody>
					<Alert color="info">
						<p className='mb-1'><strong>Concurso: </strong>{contest.name}</p>
						<p className='mb-0'><strong>Ganador Actual: </strong>{contest.winner_user ? contest.winner_user.fullname : <em>Aún no se ha elegido un ganador</em>}</p>
					</Alert>
					<h6>Nuevo Ganador:</h6>
					<ServerSideSelect
						value={selectedUser}
						placeholder="Seleccione un usuario"
						fetchOptions={searchUsers}
						onChange={value => setSelectedUser(value)}
						style={{ width: '100%', marginBottom: '15px' }}
					/>
					<Button 
						color="primary" 
						disabled={!selectedUser.value}
						onClick={onSubmit}
					>
						Actualizar Ganador
					</Button>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}