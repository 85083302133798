import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import UploadNewImage from './UploadNewImage'

export default function UploadImageModal({ 
	visible, onClose, preview, directory, filename, onSuccess, onError 
}) {
	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Subir Imagen</ModalHeader>
				<ModalBody>
					{ preview && (
						<img src={preview} alt="Vista Previa" width="100%" className='mb-3' />
					)}
					<UploadNewImage 
						directory={directory}
						filename={filename}
						handleSuccess={onSuccess}
						handleError={onError}
					/>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}