import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import OffersList from './OffersList'
import OfferProfile from './OfferProfile'

export default function Offers() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/offers" component={OffersList} />
				<Route exact path="/offers/:id" component={OfferProfile} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}