import springApi from '../../services/springApi'

export const getReactions = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/reactions', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getReaction = (id, params) => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/reactions/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeReaction = data => (
	new Promise((resolve, reject) => {
		springApi()
			.reaction('/reactions', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateReaction = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/reactions/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteReaction = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/reactions/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)