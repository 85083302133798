import springApi, { getDomain } from '../../services/springApi'

export const getSurveys = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/surveys', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getSurvey = (id, params) => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/surveys/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeSurvey = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/surveys', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateSurvey = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/surveys/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteSurvey = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/surveys/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchSurveys = async (name, token) => (
	fetch( getDomain()+`/surveys?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(survey => ({
				label: survey.name,
				value: survey.id,
			})),
		)
)