import React, { useState } from 'react'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

import EditUserModal from './EditUserModal'

import Pagination from '../../../components/Pagination'

import { deleteUser } from '../services'
import { handleError } from '../../../helpers'

export default function UsersTable({ users, reload, pagination, setPagination }) {
	const [editModal, setEditModal] = useState(null)
	
	const columns = [
		{
			title: 'Nombre',
			dataIndex: 'fullname'
		},
		{
			title: 'Cédula',
			dataIndex: 'document'
		},
		{
			title: 'Cargo',
			dataIndex: 'position'
		},
		{
			title: 'Empresa',
			dataIndex: 'company'
		},
		{
			title: 'Departamento',
			dataIndex: 'department_name'
		},
		{
			title: 'Total Aperturas',
			dataIndex: 'opens_count',
			render: t => `${t} aperturas`
		},
		{
			title: 'Total Comentarios',
			dataIndex: 'comments_made_count',
			render: t => `${t} comentarios`
		},
		{
			title: 'Acciones',
			render: (t,r) => (
				<React.Fragment>
					<Tooltip title="Editar">
						<FontAwesomeIcon 
							className="text-link" 
							icon={faEdit} 
							onClick={()=>setEditModal(r)} 
						/>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Eliminar">
						<Popconfirm
							title="Esta acción no puede ser revertida"
							okText="Eliminar"
							cancelText="Cancelar"
							onConfirm={() => handleDelete(r.id)}
							okButtonProps={{ danger:true }}
						>
							<FontAwesomeIcon className="text-link" icon={faTrash} />
						</Popconfirm>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	const handleDelete = id => {
		deleteUser(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const expandedRowRender = r => (
		<React.Fragment>
			<p className='mb-2'><strong>Correo: </strong>{r.email}</p>
			<p className='mb-2'><strong>Tipo de Contrato: </strong>{r.contract_type}</p>
		</React.Fragment>
	)
	
	return (
		<React.Fragment>
			<Table 
				dataSource={users}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				size="small"
				expandable={{ expandedRowRender }}
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				updatePagination={setPagination}
				reload={reload}
			/>
			{ editModal && (
				<EditUserModal 
					visible
					onClose={() => setEditModal(null)}
					reload={() => {
						reload()
						setEditModal(null)
					}}
					user={editModal}
				/>
			)}
		</React.Fragment>
	)
}