import React from 'react'
import { Divider } from 'antd'

import NewSubscriberForm from './partials/NewSubscriberForm'
import SubscribersTable from './partials/SubscribersTable'

export default function ModelSubscribersWidget({ model, modelType, reload }) {
	return (
		<React.Fragment>
			<SubscribersTable 
				subscribers={model.subscribers} 
				reload={reload}
			/>
			<Divider className='my-2' />
			<NewSubscriberForm 
				modelId={model.id}
				modelType={modelType}
				reload={reload}
			/>
		</React.Fragment>
	)
}