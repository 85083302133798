import { message } from 'antd'
import React from 'react'
import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

import { updateContest } from '../services'
import { handleError } from '../../../helpers'
import { Button } from 'reactstrap'

export default function ContestContentWidget({ contest }) {
	const { register, handleSubmit, formState: { errors } } = useForm({
		defaultValues: {
			description: contest.description
		}
	})

	const onSubmit = values => {
		updateContest(contest.id, values)
			.then(res => message.success(res.data.message))
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Form.Group>
					<Form.Control 
						as="textarea"
						{...register('description', { required:true })}
						placeholder="Escriba aquí el contenido principal"
						style={{ height: '150px' }}
					/>
					{ errors.description && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Button outline color='primary' type='submit'>Actualizar Contenido</Button>
			</Form>
		</React.Fragment>
	)
}