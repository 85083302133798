import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import SurveysList from './SurveysList'

export default function Surveys() {
	return (
		<React.Fragment>
			<Switch>
				<Route path="/surveys" component={SurveysList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}