import React from 'react'
import { Form } from 'react-bootstrap'

import ServerSideSelect from '../../../components/ServerSideSelect'
import { searchUsers } from '../../Users/services'

export default function RewardForm({ register, errors, selectedUser, setSelectedUser }) {
	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Premio <span className="text-danger">*</span></Form.Label>
				<Form.Control {...register('name', { required:true })} />
				{ errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			{ selectedUser && (
				<Form.Group>
					<Form.Label>Usuario Ganador <span className="text-danger">*</span></Form.Label>
					<ServerSideSelect
						value={selectedUser}
						placeholder="Seleccione un usuario"
						fetchOptions={searchUsers}
						onChange={value => setSelectedUser(value)}
						style={{ width: '100%', marginBottom: '7px' }}
					/>
				</Form.Group>
			)}
			<Form.Group>
				<Form.Label>Otorgado El <span className="text-danger">*</span></Form.Label>
				<Form.Control type="date" {...register('given_at', { required: true })} />
				{ errors.given_at && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Texto Adicional <small>(opcional)</small></Form.Label>
				<Form.Control {...register('hint', {})} />
			</Form.Group>
			<Form.Group>
				<Form.Label>Estado <span className="text-danger">*</span></Form.Label>
				<Form.Control
					as="select"
					{...register('is_active', { required: true })}
				>
					<option value="">:: Seleccionar una opción ::</option>
					<option value="1">Activo</option>
					<option value="0">Inactivo</option>
				</Form.Control>
				{ errors.is_active && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
		</React.Fragment>
	)
}