import React from 'react'
import { Empty, Table } from 'antd'
import moment from 'moment'

import Pagination from '../../../components/Pagination'

export default function GiftCardsTable({ giftCards, reload, pagination, setPagination }) {
	
	const columns = [
		{
			title: 'Usuario',
			dataIndex: 'user',
			render: t => t.fullname
		},
		{
			title: 'Documento',
			dataIndex: 'user',
			render: t => t.document
		},
		{
			title: 'Código',
			dataIndex: 'code',
		},
		{
			title: 'Fecha de Vencimiento',
			dataIndex: 'expire_at',
			render: t => moment(t).format('YYYY-MM-DD')
		},
		{
			title: 'Fecha de Creación',
			dataIndex: 'created_at',
			render: t => moment(t).format('YYYY-MM-DD hh:mm A')
		}
	]

	return (
		<React.Fragment>
			<Table 
				dataSource={giftCards}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				size="small"
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				updatePagination={setPagination}
				reload={reload}
			/>
		</React.Fragment>
	)
}