import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import App from './App'
import {store, persistor} from './store'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css'
import './assets/css/main.css'

Bugsnag.start({
	apiKey: '5074b035504c47726ce1244fbdcb4dc4',
	plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

const app = (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <HashRouter>
                <ErrorBoundary>
                    <App />
                </ErrorBoundary>
            </HashRouter>
        </PersistGate>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));