import React from 'react'
import { Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUnlink } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import { deleteSubscriber } from '../services'
import { handleError } from '../../../helpers'

export default function SubscribersTable({ subscribers, reload }) {

	const columns = [
		{
			title: 'Nombre',
			dataIndex: 'user',
			render: t => t.fullname
		},
		{
			title: 'Email',
			dataIndex: 'user',
			render: t => t.email
		},
		{
			title: 'Fecha Registro',
			dataIndex: 'created_at',
			render: t => moment(t).format('YYYY-MM-DD HH:mm')
		},
		{
			title: 'Acciones',
			render: (t,r) => (
				<React.Fragment>
					<Tooltip title="Desvincular">
						<Popconfirm
							title="Se quitara este usuario del concurso"
							okText="Desvincular"
							cancelText="Cancelar"
							onConfirm={() => handleDelete(r.id)}
							okButtonProps={{ danger: true }}
						>
							<FontAwesomeIcon icon={faUnlink} className="text-link" />
						</Popconfirm>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	const handleDelete = id => {
		deleteSubscriber(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Table 
				dataSource={subscribers}
				rowKey={r => r.id}
				columns={columns}
				size="small"
				locale={{ 
					emptyText: (
						<Empty 
							description="No se ha suscrito ningún usuario aún"
							image={Empty.PRESENTED_IMAGE_SIMPLE}
						/>
					)
				}}
			/>
		</React.Fragment>
	)
}