import React from 'react'
import { Empty, Table } from 'antd'
import moment from 'moment'

import Pagination from '../../../components/Pagination'

export default function ReactionsTable({ reactions, reload, pagination, setPagination }) {
	
	const columns = [
		{
			title: 'Usuario',
			dataIndex: 'user',
			render: t => t.fullname
		},
		{
			title: 'Documento',
			dataIndex: 'user',
			render: t => t.document
		},
		{
			title: 'Tipo de Contenido',
			dataIndex: 'model_type',
			render: t => t === 'post' ? 'Noticia' : t === 'comment' ? 'Comentario' : t === 'user' ? 'Usuario' : 'NA'
		},
		{
			title: 'Contenido',
			dataIndex: 'model',
			render: t => t ? (t.name ? t.name : t.body) :  <em>Sin registro</em>
		},
		{
			title: 'Tipo de Reacción',
			dataIndex: 'type_name'
		},
		{
			title: 'Fecha de Reacción',
			dataIndex: 'created_at',
			render: t => moment(t).format('YYYY-MM-DD hh:mm A')
		}
	]

	return (
		<React.Fragment>
			<Table 
				dataSource={reactions}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				size="small"
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				updatePagination={setPagination}
				reload={reload}
			/>
		</React.Fragment>
	)
}