import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

import ServerSideSelect from '../../../components/ServerSideSelect'
import { searchUsers } from '../../Users/services'

export default function PostForm({ register, errors, selectedUser, setSelectedUser }) {
	return (
		<React.Fragment>
		{ selectedUser && (
			<Form.Group>
				<Form.Label>Autor <span className="text-danger">*</span></Form.Label>
				<ServerSideSelect
					value={selectedUser}
					placeholder="Seleccione un usuario"
					fetchOptions={searchUsers}
					onChange={value => setSelectedUser(value)}
					style={{ width: '100%', marginBottom: '7px' }}
				/>
			</Form.Group>
		)}
			<Form.Group>
				<Form.Label>Título <span className="text-danger">*</span></Form.Label>
				<Form.Control {...register('name', { required:true })} />
				{ errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Descripción Corta <span className="text-danger">*</span></Form.Label>
				<Form.Control as="textarea" {...register('excerpt', { required:true })} style={{ height: '80px' }} />
				{ errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Fecha Vencimiento <span className="text-danger">*</span></Form.Label>
					<Form.Control type="date" {...register('expire_at', { required: true })} />
					{ errors.expire_at && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Estado <span className="text-danger">*</span></Form.Label>
					<Form.Control
						as="select"
						{...register('is_published', { required: true })}
					>
						<option value="">:: Seleccionar una opción ::</option>
						<option value="1">Publicada</option>
						<option value="0">Borrador</option>
					</Form.Control>
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Label>Video Youtube Promocional <small>(opcional)</small></Form.Label>
				<Form.Control 
					{...register('promo_video_url', {
						pattern: {
							value: /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/,
						}
					})} 
					placeholder="Ingrese un link de Youtube"
				/>
				{ errors.promo_video_url && <Form.Text className="text-warning">Debe ingresar link de video de Youtube válido.</Form.Text> }
			</Form.Group>
		</React.Fragment>
	)
}