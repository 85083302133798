import React, { useState } from 'react'
import { Badge } from 'reactstrap'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import EditRewardModal from './EditRewardModal'

import Pagination from '../../../components/Pagination'

import { deleteReward } from '../services'
import { handleError } from '../../../helpers'

export default function RewardsTable({ rewards, reload, pagination, setPagination }) {
	const [editModal, setEditModal] = useState(null)
	
	const columns = [
		{
			title: 'Premio',
			dataIndex: 'name',
			render: (t,r) => r.hint ? `${t} (${r.hint})` : t
		},
		{
			title: 'Usuario Ganador',
			dataIndex: 'user',
			render: t => t.fullname
		},
		{
			title: 'Estado',
			dataIndex: 'is_active',
			render: t => t ? <Badge color="success">Activo</Badge> : <Badge color="dark">Inactivo</Badge>
		},
		{
			title: 'Otorgado el',
			dataIndex: 'given_at',
			render: t => moment(t).format('YYYY-MM-DD')
		},
		{
			title: 'Acciones',
			render: (t,r) => (
				<React.Fragment>
					<Tooltip title="Editar" onClick={()=>setEditModal(r)} >
						<FontAwesomeIcon 
							className="text-link" 
							icon={faEdit}							
						/>{" "}
						<span className='text-link'>Editar</span>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Eliminar">
						<Popconfirm
							title="Esta acción no puede ser revertida"
							okText="Eliminar"
							cancelText="Cancelar"
							onConfirm={() => handleDelete(r.id)}
							okButtonProps={{ danger:true }}
						>
							<FontAwesomeIcon className="text-link" icon={faTrash} />{" "}
							<span className='text-link'>Eliminar</span>
						</Popconfirm>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	const handleDelete = id => {
		deleteReward(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}
	
	return (
		<React.Fragment>
			<Table 
				dataSource={rewards}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				size="small"
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				updatePagination={setPagination}
				reload={reload}
			/>
			{ editModal && (
				<EditRewardModal 
					visible
					onClose={() => setEditModal(null)}
					reload={() => {
						reload()
						setEditModal(null)
					}}
					reward={editModal}
				/>
			)}
		</React.Fragment>
	)
}