import React from 'react'
import { Comment, message, Popconfirm, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import { deleteComment } from '../services'
import { handleError } from '../../../helpers'

export default function CommentItem({ comment, reload }) {

	const handleDelete = () => {
		deleteComment(comment.id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Comment 
				author={comment.author}
				content={comment.body}
				datetime={
					<Tooltip title={moment(comment.created_at).format('YYYY-MM-DD HH:mm')}>
						<span>{moment(comment.created_at).fromNow()}</span>
					</Tooltip>
				}
				actions={[
					<Popconfirm
						title="Esta acción no puede ser revertida"
						okText="Eliminar"
						cancelText="Cancelar"
						onConfirm={() => handleDelete()}
						okButtonProps={{ danger:true }}
					>
						<FontAwesomeIcon className="text-link" icon={faTrash} />{" "}
						<span className='text-link'>Eliminar</span>
					</Popconfirm>
				]}
			/>
		</React.Fragment>
	)
}