import React from 'react'
import { message, Popconfirm, Switch, Tooltip } from 'antd'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

import { deleteCategory, toggleCategory } from '../services'
import { handleError } from '../../../helpers'

export default function CategoryItem({ category, reload, reloadCategories, model, modelType }) {
	
	const handleToggle = () => {
		toggleCategory({
			model_type: modelType,
			model_id: model.id,
			category_id: category.id
		})
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const isChecked = id => {
		let ids = model.categories.filter(c => c.id === id)
		return ids.length === 0 ? false : true
	}

	const handleDelete = () => {
		deleteCategory(category.id)
			.then(res => {
				message.success(res.data.message)
				reloadCategories()
			})
			.catch(error => handleError(error))
	}
	
	return (
		<React.Fragment>
			<Row>
				<Col>
					<Switch 
						checked={isChecked(category.id)}
						onChange={handleToggle}
						size="small"
					/>
				</Col>
				<Col className='ml-2' style={{ flex: 1 }}>
					{category.name}
				</Col>
				<Col className='px-2'>
					<Tooltip title="Eliminar">
						<Popconfirm
							title="Se eliminar esta categoría de TODO el sistema"
							okText="Eliminar"
							cancelText="Cancelar"
							onConfirm={() => handleDelete()}
							okButtonProps={{ danger:true }}
						>
							<FontAwesomeIcon className="text-link" icon={faTrash} />
						</Popconfirm>
					</Tooltip>
				</Col>
			</Row>
		</React.Fragment>
	)
}

const Row = styled.div`
	display: flex;
	margin-bottom: 7px;
`

const Col = styled.div``