import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'

import DepartmentsListModal from '../Departments/DepartmentsListModal'
import NewUserModal from './partials/NewUserModal'
import UsersTable from './partials/UsersTable'

import { handleError, parsePagination } from '../../helpers'
import { getUsers } from './services'

export default function UsersList() {
	const [users, setUsers] = useState(null)
	const [filterType, setFilterType] = useState('document')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const [showNewModal, setShowNewModal] = useState(false)
	const [showDepartmentsModal, setShowDepartmentsModal] = useState(false)

	useEffect(() => {
		!users && getUsers({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			'filter[active]': 1,
			include: 'commentsMadeCount,opensCount',
		})
			.then(res => {
				setUsers(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ users, pagination, filterType, filterValue ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Directorio de Usuarios"
				subtitle="Listado de todos los usuarios de la plataforma"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setUsers(null)}
				options={[
					{ label: 'Buscar por cédula', value: 'document' },
					{ label: 'Buscar por email', value: 'email' },
					{ label: 'Buscar por nombre', value: 'name' },
				]}
				ctaText="Agregar Usuario"
				handleCtaClick={() => setShowNewModal(true)}
				secundaryCtaText="Departamentos"
				handleSecundaryCtaClick={()=>setShowDepartmentsModal(true)}
			/>
			<Card>
				<CardBody>
					<UsersTable 
						users={users}
						reload={() => setUsers(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
			{ showNewModal && (
				<NewUserModal 
					visible
					onClose={() => setShowNewModal(false)}
					reload={() => {
						setUsers(null)
						setShowNewModal(false)
					}}
				/>
			)}
			{ showDepartmentsModal && (
				<DepartmentsListModal 
					visible
					onClose={() => setShowDepartmentsModal(false)}
					reload={() => {
						setUsers(null)
						setShowDepartmentsModal(false)
					}}
				/>
			)}
		</React.Fragment>
	)
}