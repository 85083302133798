import springApi, { getDomain } from '../../services/springApi'

export const getSurveyResponses = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/survey_responses', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getSurveyResponse = (id, params) => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/survey_responses/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeSurveyResponse = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/survey_responses', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateSurveyResponse = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/survey_responses/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteSurveyResponse = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/survey_responses/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchSurveyResponses = async (name, token) => (
	fetch( getDomain()+`/survey_responses?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(survey_response => ({
				label: survey_response.name,
				value: survey_response.id,
			})),
		)
)