import springApi, { getDomain } from '../../services/springApi'

export const getCategories = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/categories', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getCategory = (id, params) => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/categories/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeCategory = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/categories', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateCategory = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/categories/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteCategory = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/categories/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const toggleCategory = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/categories/toggle', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchCategories = async (name, token) => (
	fetch( getDomain()+`/categories?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(category => ({
				label: category.name,
				value: category.id,
			})),
		)
)