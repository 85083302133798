import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import ContestsList from './ContestsList'
import ContestProfile from './ContestProfile'

export default function Contests() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/contests" component={ContestsList} />
				<Route exact path="/contests/:id" component={ContestProfile} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}