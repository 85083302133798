import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Badge, Row, Col } from 'reactstrap'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import EditOfferModal from './EditOfferModal'

import Pagination from '../../../components/Pagination'

import { deleteOffer } from '../services'
import { handleError } from '../../../helpers'

export default function OffersTable({ offers, reload, pagination, setPagination }) {
	const [editModal, setEditModal] = useState(null)
	
	const columns = [
		{
			title: 'Tipo',
			dataIndex: 'is_selling',
			render: t => t ? <Badge color='info'>Venta</Badge> : <Badge color="danger">Compra</Badge>,
			width: '80px'
		},
		{
			title: 'Título',
			dataIndex: 'name',
			render: (t,r) => <Link to={`/offers/${r.id}`}>{t}</Link>
		},
		{
			title: 'Autor',
			dataIndex: 'user',
			render: t => `${t.name} ${t.lastname}`
		},
		{
			title: 'Vence el',
			dataIndex: 'expire_at',
			render: t => moment(t).format('YYYY-MM-DD')
		},
		{
			title: 'Estado',
			dataIndex: 'is_published',
			render: t => t ? <Badge color='success'>Publicado</Badge> : <Badge color="warning">Borrador</Badge>,
		},
		{
			title: 'Aperturas',
			dataIndex: 'opens_count',
			render: t => `${t} en total`
		},
		{
			title: 'Acciones',
			render: (t,r) => (
				<React.Fragment>
					<Tooltip title="Ver">
						<Link to={`/offers/${r.id}`}>
							<FontAwesomeIcon 
								className="text-link" 
								icon={faSearch}
							/>{" "}
							<span className='text-link'>Ver</span>	
						</Link>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Editar" onClick={()=>setEditModal(r)}>
						<FontAwesomeIcon 
							className="text-link" 
							icon={faEdit}
						/>{" "}
						<span className='text-link'>Editar</span>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Eliminar">
						<Popconfirm
							title="Esta acción no puede ser revertida"
							okText="Eliminar"
							cancelText="Cancelar"
							onConfirm={() => handleDelete(r.id)}
							okButtonProps={{ danger:true }}
						>
							<FontAwesomeIcon className="text-link" icon={faTrash} />{" "}
							<span className='text-link'>Eliminar</span>
						</Popconfirm>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	const handleDelete = id => {
		deleteOffer(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const expandedRowRender = r => (
		<React.Fragment>
			<Row>
				<Col>
					<p className='mb-2'>
						<strong>Categorias: </strong>
						{r.categories.length > 0 ? r.categories.map(c => `${c.name} | `) : <em className='hint-text'>Sin categorías</em>}
					</p>
				</Col>
				<Col>
					<p className='mb-0'>
						<strong>Etiquetas: </strong>
						{r.tags.length > 0 ? r.tags.map(c => `${c.name} | `) : <em className='hint-text'>Sin etiquetas</em>}
					</p>
				</Col>
			</Row>
			<p className='mb-2'><strong>Descripción Corta: </strong>{r.excerpt}</p>
		</React.Fragment>
	)
	
	return (
		<React.Fragment>
			<Table 
				dataSource={offers}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				size="small"
				expandable={{ expandedRowRender }}
				locale={{ emptyText: (
					<Empty 
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				updatePagination={setPagination}
				reload={reload}
			/>
			{ editModal && (
				<EditOfferModal 
					visible
					onClose={() => setEditModal(null)}
					reload={() => {
						reload()
						setEditModal(null)
					}}
					offer={editModal}
				/>
			)}
		</React.Fragment>
	)
}